
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    min-height: 100%;
}

body {
    font-family: $body-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    text-align: left;
    color: $body-color;
    position: relative;
    @include breakpoint-up(md) {
        font-size: $base-desktop-font-size;
    }
}

.app {
    &__layout {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        min-height: -webkit-fill-available;
        position: relative;
        &:before {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: '';
            background: url('../../images/pattern-blue.png');
            background-size: cover;
            z-index: -1;
        }

    }
    &__content {
        //padding: 7rem 2rem 13rem 2rem;
        padding: 7rem 2rem 3rem 2rem;
    }
}

@include breakpoint-up(sm) {
    .app {
        &__content {
            padding: 2rem 3rem 2rem 13rem;
        }
    }

}

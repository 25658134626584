
.container {
  margin: 0 auto;
  max-width: 350px;
}

.u-m-top-20 {
  margin-top: 20px;
}

.imageView {
  border-radius: 2rem;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.svg {
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.filters {
  margin: 10px 0 20px 0;
}

.filter {
  background: #FFF;
  border: 2px solid #9DC130;
  border-radius: 10px;
  color: #9DC130;
  font-weight: bold;
  margin: 0;
  padding: 10px 0;
  width: 21%;
}

.filterActive {
  background: #9DC130;
  color: #FFF;
}

.options {
  background: #FFF;
  border-radius: 10px;
  padding: 10px;
}

.colors {
  margin-bottom: 10px;
}

.color {
  border: none;
  border-radius: 500px;
  box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
  display: block;
  height: 30px;
  width: 30px;
}

.cells {
  flex-wrap: wrap;
}

.cell {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 0;
  padding-top: 21%;
  position: relative;
  flex: 0 0 21%;
}

.actions {
  display: flex;
  margin-top: 10px;
}

.action {
  flex: 1;
  margin-right: 5px;
}
.action:nth-child(2) {
  margin-right: 0;
  margin-left: 5px;
}

.sliders {
  border-top: 1px solid #CCC;
  padding-top: 20px;
}

.sliderSet {
  align-items: center;
  color:#909090;
  display: flex;
  font-weight: bold;
  flex-direction: column;
  justify-content: center;
}

.sliderLabel {
  margin-top: 10px;
}

.skinColors {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.skinColorLeft {
  margin-right: 10px;
}
@import '../../styles/variables';
@import '../../styles/mixins';



.dashboard {
    display: flex;
}

.welcome {
    display: none;
    margin-bottom: 2rem;
    max-width: 400px;
}

.welcomeInner,
.surveyInit {
    background:#FFF;
    border-radius: 30px;
    text-align: center;
    color: $purple;
    padding: 40px;
}
.surveyInit {
    padding: 20px;
    margin-top: 2rem;
    display: flex;
    color: $body-color;
    font-weight: bold;
    text-align: left;
    align-items: center;
    p {
        font-size: 2rem;
        line-height: 1.2;
    }
}
.welcomeBoomerHolder {
    width: 200px;
    margin: 0 auto 20px auto;
    height: 200px;
    border-radius: 300px;
    background: #ffd653;
    display: flex;
    justify-content: center;
    align-items: center;
}
.welcomeBoomer {
    width: 180px;
    height: auto;
}

.gemPanel {
    background: #FFF;
    border-radius: 2rem;
    color: #4a4a4a;
    margin-top: 5px;
    padding: 5px 10px 5px 34px;
    display: flex;
    background:#c6cbb5;
    justify-content: center;
    font-weight: bold;
    color: #4E406E;
}

.gem {
    height: auto;
    margin-right: 10px;
    width: 2rem;
}

.mascot {
    border: 1px solid #FFF;
    border-radius: 200px;
    height: auto;
    margin-bottom: 1rem;
    width: 7.5rem;
}

.appLinks {
    width: 100%;
}

.appLink {
    align-items: center;
    background: white;
    border-radius: 3rem;
    display: flex;
    margin-bottom: 2rem;
    padding: 2rem;
    color: #4E406E;
    font-size: 30px;
    font-weight: bold;
    &:hover {
        background: $gray-1;
    }
    &Disabled {
        background: $gray-2;
        pointer-events: none;
    }
}

.iconHolder {
    background: #ffd653;
    width: 100px;
    height: 100px;
    border-radius: 200px;
    margin-right: 20px
}

.iconImg, .boomerImg {
    width: 100px;
    height: auto;
}

.iconImg {
    margin-right: 20px;
}

.appLinkPop,
.appLinkPopSmall {
    margin-right: 0.5rem;
    font-size: 40px;
    font-weight: 700;
}

.appLinkPopSmall {
    font-size: 3rem;
}

.dashAva {
    border-radius: 1rem;
    width: 9rem;
}

.appLinkImgBoomer {
    max-height: 150px;
}
.surveyBannerMobile {
    margin-bottom: 2rem;
}
@include breakpoint-up(sm) {
    .appLinks {
        display: flex;
        flex-direction: column;
        max-width: 600px;
        width: 100%;
    }
    .welcome {
        display: block;
        margin-right: 20px;
    }
    .appLink {
        border-radius: 3rem;
        flex: 1;
    }
    .dashAva {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .surveyBannerMobile {
        display: none;
    }
}
a[disabled] {
    pointer-events: none;
}

.btnReset {
    border: 0;
    padding: 0;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

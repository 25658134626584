.confirm-overlay {
    &__body {
        align-items: center;
        background: #FFFFFF;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        padding: 30px;
        position: fixed;
        right: 0;
        text-align: center;
        top: 0;
        z-index: 500;
        &--winner {
            background: #4E406E;
            color: #FFCE89;
            .button {
                width: 100%;
            }
        }
    }
    &__close-button {
        display: none;
    }
    &__boomer {
        display: none;
    }
    &__message {
        font-size: 18px;
        margin-top: 20px;
    }
    &__title {
        font-size: 24px;
        margin-top: 20px;
    }
    &__buttons {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }
    &__button {
        margin: 0 5px;
    }
    &__hero {
        height: auto;
        width: 250px;
    }
    &__lads {
        height: auto;
        width: 350px;
    }
    &__earnings {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 20px 0;
        text-align: center;
    }
    &__earnings-coins {
        height: 16px;
        margin: 0 5px;
        width: auto;
    }
    &--hide {
        display: none;
    }
}

.view-winner-overlay__inner {
    margin: 20px 0;
}

@media screen and (min-width: 750px) {
    .confirm-overlay {
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 451;
        &__body {
            border-radius: 24px;
            max-width: 80%;
            position: relative;
            &--winner {
                background: #FFF;
                color: #4a4a4a;
                width: 600px;
            }
        }
        &__close-button {
            align-items: center;
            background: darken(#E91D1D, 10%);
            border: none;
            border-radius: 30px;
            cursor: pointer;
            display: flex;
            height: 40px;
            justify-content: center;
            padding: 0;
            position: absolute;
            right: -30px;
            top: -30px;
            width: 40px;
            &:hover {
                background: #E91D1D;
            }
        }
        &__close-icon {
            color: #FAFAFA;
            font-size: 30px;
        }
        &__boomer {
            background: #FFFFFF;
            border: 5px solid #FCC62D;
            border-radius: 61px;
            display: block;
            height: 70px;
            left: -20px;
            overflow: hidden;
            position: absolute;
            top: -20px;
            width: 70px;
        }
        &__hero {
            position: absolute;
            top: -125px;
            width: 300px;
        }
        &__lads {
            bottom: -50px;
            position: absolute;
            width: 550px;
        }
        &__earnings-coins {
            height: 20px;
        }
        &--hide {
            display: none;
        }
    }
    .view-winner-overlay__inner {
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        font-size: 18px;
        margin: 0;
        padding: 20px 20px 50px 20px;
        width: 100%;
    }
}
.auth-page {
    &__container {
        display: flex;
        justify-content: center;
        &:before {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: '';
            background: linear-gradient(to bottom, $primary-dark 0%, $primary calc(100% - 11rem)) no-repeat left top;
            background-size: cover;
            z-index: -1;
        }
    }
    &__panel {
        background: #FAFAFA;
        border-radius: 40px;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        margin: 85px 10px;
        max-width: 440px;
        padding: 0 20px 40px
    }

    &__logo {
        margin-top: -50px;
    }

    &__title {
        margin: 20px 0;
        text-align: center;
        text-transform: uppercase;
    }

    &__link {
        cursor: pointer;
        &:hover {
            color: lighten($body-color, 10%);
        }
    }

    &__logged-out {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        &__icon {
            height: 42px;
            width: 42px;
        }
        &__text {
            font-size: 16px;
            font-weight: lighter;
            margin-bottom: 0;
            margin-left: 10px;
        }
    }
}
.store {
	background: #FFF;
	position: relative;
}
.store__loading {
	position: absolute;
	left: 0;
	top: 0;
	background: rgba(255,255,255, 0.9);
	right: 0;
	bottom: 0;
	z-index: 2000;
	display: flex;
	align-items: center;
	justify-content: center;
}
.store__preview {
	height: 220px;
	position: relative;
}

.store__mob-header {
	background: #4E406E;
	display: flex;
	padding: 10px;	
}

.store__header-text {
	color: #FFF;
	flex-grow: 1;
	line-height: 38px;
	text-align: center;
}

.store__header-coins {
	height: auto;
	margin-right: 5px;
	width: 20px;
}

.store__header-bucks {
	align-items: center;
	color: #FFCE89;
	display: flex;
	justify-content: center;
	width: 69px;
}

.store__controls {
	background: #ececec;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-flow: column nowrap;
	height: calc(100vh - 280px);
	overflow: hidden;
	padding-bottom: 5px;
}

.store__wallet {
	display: none;
}

.store__tabs {
	background: #00A5FF;
	display: flex;
}
.store__tab {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	color: #FFF;
	font-weight: bold;
	cursor: pointer;
	flex: 1;
	padding: 15px;
	text-align: center;
	&:hover {
		background: lighten(#00A5FF, 10%);
		@at-root .store__tabs{
			background: lighten(#00A5FF, 10%);	
		} 
	}
	&--active, &--active:hover {
		background: #ececec;
		color: #000;
	}
}

.store__categories {
	display: flex;
}
.store__category-tab {
	align-items: center;
	background: #F1F1F1;
	border: 2px solid #E4E4E4;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	flex: 1;
	height: 55px;
	justify-content: center;
	margin: 10px 5px 0 5px;
	&--active {
		background: #FFF;
		border: 2px solid #00A5FF;
	}
	&:hover {
		background: #FFF;
	}
}
.store__category-boomer {
	height: auto;
	width: 22px;
}
.store__category-img {
	height: auto;
	width: 30px;
}
.store__items {
	display: flex;
	flex: 1;
	padding-top: 10px;
	position: relative;
	width: 100%;
}
.store__items-list {
	background: #FFF;
	border: 1px solid #D6D6D6;
	border-radius: 10px;
	height: 100%;
	margin: 0 5px 10px 5px;
	overflow-y: scroll;
	padding: 5px;
	width: 100%;
	&--padded {
		padding: 5px 5px 60px 5px;
	}
}
.store__actions {
	background: #FFF;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	bottom: 1px;
	display: flex;
	left: 6px;
	padding-bottom: 5px;
	position: absolute;
	right: 6px;
	z-index: 200;
}
.store__actions .button {
	flex: 1;
	margin: 0 5px;
}
.store__item {
	background: #F1F1F1;
	border: 1px solid #F1F1F1;
	border-radius: 10px;
	cursor: pointer;
	float: left;
	margin: 5px;
	overflow: hidden;
	padding: 10px 5px;
	position: relative;
	width: calc( 25% - 10px );
	&:hover {
		background: #FFF;
		border: 1px solid #F0F0F0;
	}
	&--wearing, &--wearing:hover {
		background: #FCC62D;
	}
	&--owned {
		padding: 5px 5px 15px 5px;
	}
}
.store__no-item {
	color: #4a4a4a;
	padding: 5px;
	text-align: center;
}
.store__item-price {
	align-items: center;
	background: #00A5FF;
	border-radius: 10px;
	color: #FFF;
	display: flex;
	height: 40px;
	justify-content: center;
	margin: 5px;
	font-size: 16px;
	text-align: center;
	width: calc(100% - 10px);
}
.store__item-desc {
	text-transform: capitalize;
	font-weight: bold;
}
.store__item-coins {
	height: 18px;
	margin: 0 2px 0 10px;
	width: auto;
}
.store__item-owned {
	background: #00A5FF;
	bottom: 0;
	color: #FFF;
	font-weight: bold;
	font-size: 12px;
	left: 0;
	padding: 2px 0;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}

.store__item-img {
	background-position: 50% 50%;
    background-repeat:   no-repeat;
    background-size: contain;
	display: block;
	height: 50px;
	width: 100%;
}

.store__item-img--remove {
	color: #4a4a4a;
	font-size: 12px;
	font-weight: bold;
	line-height: 50px;
	text-align: center;
	text-transform: uppercase;
}

.store__spotlight {
	height: auto;
	left: 5px;
	position: absolute;
	top: 0;
	width: 60px;
	&--right {
		left: initial;
		right: 5px;
	}
}
.store__preview .boomer-holder {
	text-align: center;
	height: 100%;
	width: 100%;
	padding: 10px;
	position: relative;
}
.boomer-holder__badge {
	position: absolute;
	top: 63%;
	left: 50%;
	height: auto;
	margin-left: -26px;
	width: 52px;
}
.store__purchase-overlay {
	&-items {
		border: none;
		border-top: 1px solid #F2F2F2;
		border-radius: 0;
		margin: 20px -5px 0;
		padding: 15px 0 0;
	}
	&-coins {
		height: 18px;
		margin: 0 5px;
		position: relative;
		top: 2px;
	}
	.store__item {
		cursor: default;
		&:hover {
			background: #F1F1F1;
			border: 1px solid #F1F1F1;
		}
	}
	&-message {
		display: flex;
		align-items: center
	}
}

.store__badge {
	background: #f5f5f5;
	border: 1px solid #f5f5f5;
	border-radius: 10px;
	color: #005F62;
	cursor: pointer;
	display: flex;
	margin-bottom: 10px;
	padding: 10px 10px 0 0;
	&:hover {
		background: #FFF;
	}
	&--disabled {
		cursor: default;
		&:hover {
			background: #F5F5F5;
		}
	}
	&--active {
		background: #00A5FF;
		color: #FFF;
		&:hover {
			background: #00A5FF;
		}
	}
}
.badge__img {
	background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 80px;
    margin-right: 10px;
    width: 80px;
}
.badge__equipped {
	color: #F5A623;
	font-size: 14px;
}
.badge__quali {
	color: #FF6346;
	font-size: 12px;
	margin-top: 5px;
	position: relative;
}
.badge__lock {
	position: absolute;
	left: -17px;
	top: -6px;
}
.preview-badge {
	cursor: pointer;
	left: 20px;
	position: absolute;
	top: 140px;
	width: 70px;
	&--rank {
		left: initial;
		right: 20px;
	}
	&__badge {
		height: auto;
		width: 100%;
	}
}
@media screen and (min-width:750px) {
	.store {
		border-radius: 10px;
	    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
		height: calc(100vh - 80px);
		overflow: hidden;
	}
	.store__controls {
		box-shadow: none;
		height: calc(100% - 276px);
		padding-bottom: 10px;
	}
	.store__mob-button {
		display: none;
	}
	.store__header-text {
		padding-left: 69px;
	}
}

@media screen and (min-width: 1000px) {
	.store {
		background: #ececec;
    	border: none;
		border-radius: 15px;
		box-shadow: none;
		display: flex;
		height: calc(100vh - 100px);
		max-width: 800px;
		padding: 10px;
	}
	.store__mob-header {
		display: none;
	}
	.store__header {
		background: #00A5FF;
		color: #FFF;
		font-size: 20px;
		font-weight: bold;
		line-height: 50px;
		text-align: center;
	}
	.store__preview {
		background: none;
		border-radius: 15px;
		height: 100%;
		flex: 1;
		overflow: hidden;
	}
	.store__preview .boomer-holder {
		background: #FFF;
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;
		height: auto;
		padding: 50px 10px 50px 10px;
	}
	.preview-badge {
		left: 5px;
		top: 290px;
		width: 50px;
		&--rank {
			left: initial;
			right: 5px;
		}
	}
	.store__preview .boomer-holder__image, .store__preview .boomer-holder__loader {
		height: auto;
		width: 220px;
		margin: 0 auto;
	}
	.boomer-holder__badge {
		top: 58.1%;
	}
	.store__controls {
		background: none;
		border-radius: 15px;
		height: initial;
		margin-left: 10px;
		padding-bottom: 0;
		width: 380px;
	}
	.store__tabs, .store__categories, .store__items {
		background: #F5f5f5;
	}
	.store__tabs {
		background: #00A5FF;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		overflow: hidden;
	}
	.store__tab {
		&--active, &--active:hover {
			background: #F5F5F5;
		}
	}
	.store__items-list {
		margin: 0;
	}
	.store__items {
		padding: 10px 5px 5px 5px;
	}
	.store__spotlight {
		top: 50px;
		width: 50px;
		z-index: 200;
	}
	.store__coins {
		height: auto;
		margin: 0 5px 0 10px;
		width: 24px;
	}
	.store__bucks {
		color: #FFF;
		font-size: 24px;
		margin: 0 10px 0 0;
		padding-top: 1px;
	}
	.store__wallet {
		align-items: center;
		background: #4E406E;
		border-radius: 15px;
		color: #D4D4D4;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		height: 46px;
		justify-content: center;
		margin-bottom: 10px;
		text-align: center;
	}
	.store__categories {
		padding: 0 5px;
	}

	.store__items {
		padding: 10px;
	}
	.store__actions {
		bottom: 11px;
		left: 11px;
		padding: 0 5px 10px 5px;
		right: 11px;
	}
	.store__purchase-overlay {
		&-items {
			margin: 20px -5px 0;
		}
	}
}

@media screen and (min-width: 1100px) {
	.store__spotlight {
		width: 70px;
	}
	.boomer-holder__badge {
		top: 60.1%;
	}
	.preview-badge {
		left: 5px;
		top: 300px;
		width: 75px;
		&--rank {
			left: initial;
			right: 10px;
		}
	}
	.store__controls {
		width: 400px;
	}
}

.boomer-holder {
    .imageloader {
        height: 100%;
    }
    &__loader {
        animation: boomer-loader-pulse 0.9s infinite;
        max-height: 100%;
        max-width: 100%;
    }
    &__hidden-canvas {
        display: none;
    }
    &__image {
        max-height: 100%;
        max-width: 100%;
    }
    &--in-confirm {
        height: 100%;
        padding: 10px;
        overflow: hidden;
        text-align: center;
        margin: 0 auto;
        width: 70px;
        .boomer-holder__image,
        .boomer-holder__loader {
            height: 96px;
            left: -28px;
            max-height: none;
            max-width: none;
            position: relative;
            top: -23px;
        }
    }
    &--dashboard {
        .boomer-holder__image,
        .boomer-holder__loader {
            position: relative;
            max-height: none;
            max-width: none;
        }
    }
    &--in-heading {
        height: 100%;
        padding: 10px;
        overflow: hidden;
        text-align: center;
        margin: 0 auto;
        width: 61px;
        .boomer-holder__image,
        .boomer-holder__loader {
            height: 87px;
            left: -28px;
            max-height: none;
            max-width: none;
            position: relative;
            top: -23px;
        }
        &-with-rank {
            height: 100%;
            width: 61px;
            .boomer-holder__image,
            .boomer-holder__loader {
                height: 87px;
                left: -14px;
                max-height: none;
                max-width: none;
                position: relative;
                top: -12px;
            }
        }
    }
    &--in-main-menu {
        height: 100%;
        overflow: hidden;
        text-align: center;
        margin: 0 auto;
        border-radius: 35px;
        width: 35px;
        .boomer-holder__image,
        .boomer-holder__loader {
            position: relative;
            height: 68px;
            left: -16px;
            max-height: none;
            max-width: none;
            top: -12px;
        }
    }
    &--in-panel {
        height: 100%;
        padding: 1px;
        overflow: hidden;
        text-align: center;
        width: 100%;
    }
}

.badge-info {
    background-image: linear-gradient(-180deg, #F0973F 0%, #E24800 100%);
    box-shadow: 0px 0px 15px 10px rgba(233,7,255,0.50);
    border: 1px solid #FFA257;
    border-radius: 15px;
    min-height: 80px;
    padding: 10px 10px 10px 80px;
    position: absolute;
    left: 0;
    top: 80px;
    width: 100%;
    z-index: 201;
    &--bp {
        top: 240px;
    }
    &--notification {
        animation: slideIn 0.5s ease 0.5s forwards;
        bottom: 20px;
        cursor: pointer;
        left: initial;
        right: -500px;
        top: initial;
        width: calc(100% - 40px);
        z-index: 5000;
    }
    &--store {
        left: 20px;
        top: 200px;
        width: calc(100% - 40px);
    }
    &__badge {
        background-position: 50% 50%;
        background-repeat:   no-repeat;
        background-size: contain;
        display: block;
        height: 100px;
        left: -10px;
        position: absolute;
        top: -10px;
        width: 100px;
    }
    &__coins {
        height: 70px;
        width: auto;
        position: absolute;
        top: -10px;
        left: 20px;
    }
    &__close {
        align-content: center;
        background: #FFF;
        border-radius: 200px;
        cursor: pointer;
        display: flex;
        height: 36px;
        justify-content: center;
        position: absolute;
        right: -18px;
        top: -18px;
        width: 36px;
    }
    &__close-img {
        height: 16px;
        margin-top: 10px;
        width: auto;
    }
    &__desc {
        color: #FFF;
        margin-top: 20px;
    }
    &__inner {
        margin-top: -22px;
        text-align: center;
    }
    &__title {
        color: #7C0096;
        font-size: 24px;
        text-shadow: 2px 2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, -2px -2px 0 #FFF, 2px 0px 0 #FFF, 0px 2px 0 #FFF, -2px 0px 0 #FFF, 0px -2px 0 #FFF;
    }
}

@media screen and (min-width: 750px) {
    .boomer-holder {
        &--in-main-menu {
            border-radius: 40px;
            width: 40px;
            .boomer-holder__image,
            .boomer-holder__loader {
                height: 73px;
                left: -16px;
            }
        }
    }
    .badge-info {
        max-width: 500px;
    }
}

@media screen and (min-height: 700px) and (min-width: 750px) {
    .boomer-holder {
        &--in-main-menu {
            border-radius: 45px;
            width: 45px;
            .boomer-holder__image,
            .boomer-holder__loader {
                height: 80px;
                left: -17px;
            }
        }
    }
}

@keyframes boomer-loader-pulse {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes slideIn {
    0% {
        right: -500px;
    }
    90% {
        right: 30px;
    }
    100% {
        right: 20px;
    }
}

@keyframes slideIn {
    0% {
        right: -500px;
    }
    90% {
        right: 30px;
    }
    100% {
        right: 20px;
    }
}
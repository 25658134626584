.button {
	background: #00A5FF;
	border-radius: 12px;
	color: #FFF;
	cursor: pointer;
	display: block;
	font-weight: bold;
 	font-size: 18px;
    padding: 0 10px;
    text-align: center;
    line-height: 34px;
    text-decoration: none;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    &:hover {
        background: darken(#00A5FF, 10%);
    }
    &:active {
        background: lighten(#00A5FF, 10%);
    }
    &:focus {
        outline: none;
    }
    &:disabled {
        opacity: 0.5 !important;
        pointer-events: none;
    }
}
.button__image {
    height: 18px;
}
.button--bordered {
    background: #00A5FF;
    border: 4px solid #FCC62D;
    box-shadow: inset 0 -4px 0 rgba(0,0,0,0.3);
    border-radius: 12px;
    font-size: 20px;
    line-height: 20px;
    padding: 10px 20px 10px 20px;
    &:hover {
        background: #00A5FF;
        box-shadow: inset 0 0px 0 rgba(0,0,0,0.3);
        padding: 11px 20px 9px 20px;
    }
    &:focus {
        outline:none;
    }
    &:active {
        box-shadow: inset 0 4px 0 rgba(0,0,0,0.3);
        padding: 14px 20px 6px 20px;
    }
}
.button--purple {
    background: #4E406E;
    &:hover {
        background: #4E406E;
    }
}
.button--yellow {
    background: #FCC62D;
    color: #4E406E;
    &:hover {
        background: darken(#FCC62D, 10%);
    }
    &:active {
        background: lighten(#FCC62D, 10%);
    }
    &.button--bordered {
        border: 4px solid #00A5FF;
    }
}
.button--red {
    background: #E91D1D;
    &:hover {
        background: darken(#E91D1D, 5%);
    }
    &:active {
        background: lighten(#E91D1D, 10%);
    }
}
.button--green {
    background: #23B232;
    &:hover {
        background: darken(#23B232, 5%);
    }
    &:active {
        background: lighten(#23B232, 10%);
    }
}
.button--tickles {}
.button--white {
    background: #FFFFFF;
    color: #00A5FF;
    &:hover {
        background: darken(#FFFFFF, 5%);
    }
    &:active {
        background: lighten(#00A5FF, 10%);
        color: #FFFFFF;
    }
    &.button--bordered {
        border: 4px solid darken(#00A5FF, 10%);;
    }
}
.button--outline {
    background: none;
    border: 2px solid #00A5FF;
    color: #00A5FF;
    display: inline-block;
    padding: 0 10px;
    &:hover {
        background: #00A5FF;
        color: #FFF;
    }
}
.button--full-width {
    width: 100%;
}
.button--no-border {
    border: none;
}
.button--inline-block {
    display: inline-block;
}

.tickles-button, .winner-tickle-button {
    background: #FF7600;
    border: none;
    border-radius: 20px;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    padding: 5px 30px 5px 35px;
    position: absolute;
    right: 0;
    top: -50px;
    &__icon {
        height: auto;
        left: -8px;
        position: absolute;
        top: -2px;
        width: 34px;
    }
    &__counter {
        border-radius: 200px;
        font-size: 14px;
        line-height: 24px;
        background: #E91D1D;
        height: 24px;
        right: 1px;
        position: absolute;
        top: 1px;
        width: 24px;
    }
    &--review {
        top: 276px;
        right: 175px;
        z-index: 10;
    }
    &--preview {
        display: none;
    }
}
.winner-tickle-button {
    cursor: pointer;
    right: 20px;
    top: 20px;
    &:hover {
        background: lighten(#FF7600, 10%);
    };
}
@media screen and (min-width: 750px) {
    .tickles-button--review {
        top: 254px;
    }
}
@media screen and (min-width: 1000px) {
    .tickles-button {
        border: 4px solid #FCC62D;
        border-radius: 12px;
        box-shadow: inset 0 -4px 0 rgba(0,0,0,0.3);
        cursor: pointer;
        font-size: 20px;
        line-height: 22px;
        margin-top: 20px;
        padding: 10px 20px 10px 20px;
        position: relative;
        right: initial;
        text-align: center;
        text-decoration: none;
        top: initial;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        width: 100%;
        &__icon {
            left: calc(50% - 70px);
            top: 4px;
            width: 26px;
        }
        &__counter {
            right: calc(50% - 70px);
        }
        &:hover {
            box-shadow: inset 0 0px 0 rgba(0,0,0,0.3);
            padding: 11px 20px 9px 20px;
            .tickles-button__counter {
                top: 2px;
            }
            .tickles-button__icon {
                top: 5px;
            }
        }
        &:focus {
            outline:none;
        }
        &:active {
            box-shadow: inset 0 4px 0 rgba(0,0,0,0.3);
            background: lighten(#FF7600, 10%);
            padding: 14px 20px 6px 20px;
        }
    }
}

.entry-history {
    background: #FFF;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 5px;
    padding: 4px 4px 2px 18px;
    position: relative;
    line-height: 16px;
    &:hover {
        color: rgba(35, 178, 50, 1);
    }
    &--save {
        background: #00A5FF;
        border: none;
        color: #FFF;
        margin-right: 5px;
        padding: 4px 8px 2px 8px;
        width: 70px;
        &:focus {
            outline: none;
        }
        &:hover {
            color: #FFF;
        }
    }
    &--disabled {
        opacity: 0.5;
        cursor: default;
    }
    &__icon {
        background: rgba(35,178,50, 1);
        border-radius: 10px;
        display: block;
        height: 10px;
        left: 4px;
        top: calc(50% - 5px);
        position: absolute;
        width: 10px;
    }
}
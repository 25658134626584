@import '../../../../styles/variables';

.card {
    &Img {
         position: relative;
         flex-basis: 15rem;
         max-width: 15rem;
         min-height: 21.5rem;
         cursor: pointer;

         img {
            height: 21.5rem;
            object-fit: cover;
            border-radius: 0.5rem;
         }
    }
    &Name {
        position: absolute;
        top: 12.6rem;
        text-align: center;
        font-size: 1.4rem;
        width: 10rem;
        color: #bbbbbb;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1;
        transform: translateX(-50%);
        left: 50%;
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &Price {
        margin: 1rem auto 0 auto;
        padding: 1rem 0.5rem;
        display: block;
        width: 100%;
        height: 4rem;

        img {
            display: inline-block;
            vertical-align: middle;
            width: 1.6rem;
            height: auto;
        }
        &Locked {
            font-size: 1.2rem !important;
            padding: 0 !important;
        }
    }
}

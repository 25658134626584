@import '../../styles/variables';
@import '../../styles/mixins';
.loginWrapper {
    display: flex;
    max-width: 110rem;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    &Main {
        width: 100%;
    }
    &Side {
        background: #FAFAFA;
        border-radius: 2rem;
        padding: 1rem;
        max-width: 70rem;
        width: 100%;
    }
}

@include breakpoint-up(lg) {
    .loginWrapper {
        flex-direction: row;
        align-items: flex-start;
        &Main {
            flex-grow: 1;
        }
    
        &Side {
            flex-basis: 36rem;
            max-width: 36rem;
            flex-shrink: 0;
            margin: 0rem 0 0 3rem;
        }
    }
}
.loginHeader {
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 1rem;
    padding: 1rem;
    &Logo {
        width: 50px;
        height:auto;
        align-items: center;
        font-weight: bold;
        img {
            margin-right: 1rem;
            max-width: 4.2rem;
        }
    }

}

.login {
   // padding: 2rem;
    &MobileToggle {
        display: flex;
        align-items: center;
        background: #EEEEEE;
        padding: 1rem;
        border-radius: 2rem 2rem 0 0;
        cursor: pointer;
        span {
            display: block;
            font-weight: bold;
            margin-left: .5rem;
        }
        svg {
            margin-left: auto;
        }
    }
    &Mobile {
        display: none;
        position: absolute;
        top: 4rem;
        width: 100%;
        z-index: 2;
        box-shadow: 0 0.2rem .3rem 0 rgba(0, 0, 0, 0.16);
        &Visible {
            display: block;
        }
        &Link {
            background: #EEEEEE;
            padding: .6rem 1rem;
            border-top: 1px solid lighten(#ccc, 5%);
        }
    }
    &Opts {
        display: none;
        justify-content: space-between;
        flex-direction: column;
        border-radius: 3rem 3rem 0 0;   
        &Link {
            flex: 1;
            background: #EEEEEE;
            padding: 1rem;
            position: relative;
            line-height: 1.4;
            font-size: 1.8rem;
            a {
                color: lighten($body-color, 10%);
                &:hover {
                    color: $primary;
                }
            }
            span {
                display: block;
                font-weight: bold;
                margin-left: .5rem;
            }
            &:first-of-type {
                border-radius: 3rem 3rem 0 0;
            }
  
            &Active {
                color: $body-color;
                &:after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 100%;
                    left: calc(50% - 1rem);
                    border-left: 1rem solid transparent;
                    border-right: 1rem solid transparent;
                    border-top: 1rem solid #FFD653;
                }
            }
        }
    }
    &Panel {
        max-width: 70rem;
        margin: 2rem auto;
        margin-top: 0;
        background: #FAFAFA;
        border-radius: 2rem;
        &Content {
            padding:  1.5rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &Main {
          margin: auto;
    
        }
        &Side {
            margin-bottom: 1rem;
            flex-basis: 27rem;
            max-width: 27rem;
            position: relative;
            display: none;
            &Avatar {
                width: 70px;
                height: auto;
                position: relative;

                left: 50%;
                margin-left: -45px;
                bottom: -2rem;
                z-index: 3000;
            }
            &Logo {
                max-width: 7rem;
                height: auto;
            }
        }
    }
    &:before {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: '';
        background: linear-gradient(to bottom, $primary-dark 0%, $primary calc(100% - 11rem)) no-repeat left top;
        background-size: cover;
        z-index: -1;
    }
    &Error {
        font-weight: bold;
        color: #A40202;
        margin-bottom: 1rem;
        text-align: center;
    }
}

.infoPanelMobile {
    display: flex;
    align-items: center;
    margin: 1rem;
    &Avatar {
        max-width: 5rem;
        height: auto;
        flex-basis: 5rem;
        margin-right: -1rem;
        position: relative;
        z-index: 1;
    }
    &Main {
        padding: 1rem 1.3rem;
        border-radius: .5rem;
        background: $gray-2;
        flex: 1;
    }
}
.infoPanel {
    background: #FFF;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    padding: 2rem;
    border-radius: 2rem;
    position: relative;
    margin-right: 1rem;
    font-weight: bold;
    text-align: center;
    &Title {
        display: flex;
        align-items: flex-start;
        font-weight: bold;
        svg {
            margin-right: .6rem;
            flex-basis: 3rem;
            flex-shrink: 0;
        }
    }
}
.schoolInfo {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 3px solid $gray-2;
    display: flex;
    align-items: center;
    &Code {
        display: flex;
        align-items: center;
        font-weight: bold;
        margin-bottom: 0rem;
        font-size: 2rem;
    }
    &Logo {
        max-width: 8rem;
        flex-basis: 8rem;
        margin-right: 1rem;
        border-radius: .4rem;
    }

}
.backLink {
    color: $body-color;
    cursor: pointer;
    background: none;
    text-transform: none;
    font-weight: bold;
    padding: 0;
    border:none;
    display: flex;
    align-items: center;
    width: 100%;
    svg {
        margin-right: .5rem;
    }
    &:hover {
        text-decoration: none;
        background: none;
    }
}

.pinWrapper {
    display: flex;
    justify-content: space-between;
    &Field {
        width: 4.5rem;
        height: 4.5rem;
        font-size: 1.6rem;
        text-align: center;
        outline: none;
        border: 1px solid darken($gray-2, 10%);
        transition-property: color, border, box-shadow, transform;
        transition-duration: 250ms;
        margin: 0 0.4rem 2.5rem 0.4rem;
        -webkit-appearance: none;
        border-radius: 1rem;
        &:focus {
            outline: none;
            box-shadow: 0 0 7px lighten($primary, 30%);
            border: 1px solid $primary;
        }
    }
}

@include breakpoint-up(sm) {
    .login {
        &MobileToggle,
        &Mobile {
            display: none;
        }
        &Opts {
           flex-direction: row;
           display: flex;
           &Link {
                text-align: center;
                display: block;
                span {
                    font-size: 2.2rem;
                    margin-left: 0;
                }
                &Active {
                    background: #FFD653;
                }
                &:first-of-type {
                    border-radius: 3rem 0 0 0;
                    border-right: 1px solid #ccc;
                }
                &:last-of-type {
                    border-radius: 0 3rem 0 0;
                    border-left: 1px solid #ccc;
                }
           }
        }
        &Panel {
            border-radius: 5rem;
            &Main {
                margin: 1rem 1rem  0 0;
            }
            &Content {
                display: flex;
                padding: 3rem;
            }
            &Side {
                display: block;
            }
        }
    }
    .infoPanelMobile {
        display: none;
    }

    .pinWrapper {
        &Field {
            width: 6rem;
            height: 6rem;
            font-size: 3.2rem;
            border-radius: 2rem;
            &:focus {
                transform: scale(1.05);
            }
        }
    }
}

.headerFlag {
    border: 1px solid #CCC;
    padding: 5px;
    display: block;
    margin-left: auto;
}

.headerFlagImg {
    width: 30px;
    height: auto;
}

.selectContainer {
    display: block;
    padding: 40px;
    position: relative;
    text-align: center;
}

.option {
    border: 1px solid #CCC;
    padding: 20px 10px 10px 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
    cursor: pointer;
    flex: 1;
    margin: 0 5px;
}

.optionSelected {
    background: #ffd653;
}

.countryList {
    display: flex;
    margin-bottom: 20px;
}

.countrySub {
    font-size: 18px;
    margin-bottom: 10px;
}

.close {
    position: absolute;
    top: -14px;
    left: -14px;
    height: 38px;
    width: 38px;
    background: #FFF;
    border-radius: 100px;
    padding: 5px;
    border: 1px solid #000;
    cursor: pointer;
    &:hover {
        background: #eae9e9;
    }
}

.countrySelectImgAlt {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
}

.countrySelectPara {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.addToDevice {
    margin-top: 20px;
    text-align: center;
    text-decoration: underline;
}

.addToDeviceText {
    cursor: pointer;
}

.wondeBtn {
    background: #3663FF;
    color: #fff;
    padding: 5px 20px;
    border-radius: 10px;
    display: block;
    margin: auto;
    max-width: 200px;
}
.wondeWrapper {
    padding: 0 3rem 3rem 3rem;
    text-align: center
}
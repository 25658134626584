.bg0 {
    background: linear-gradient(#FF5D6A, #FF9D00);
}
.bg1 {
    background: linear-gradient(#001895, #00DEF3);
}
.bg2 {
    background: linear-gradient(#008A90, #00F2CA);
}
.bg3 {
    background: linear-gradient(#FF0000, #FFFF65);
}
.bg4 {
    background: linear-gradient(#3D3D3D, #E5E5E5);
}
.bg5 {
    background: linear-gradient(#93A500, #FFAE00);
}
.bg6 {
    background: linear-gradient(#366CF3, #F0EBF7);
}
.bg7 {
    background: linear-gradient(#5D8962, #B9B9B9);
}
.bg8 {
    background: linear-gradient(#CDCF00, #FFF800);
}
.bg9 {
    background: linear-gradient(#A60000, #FF8C8B);
}
.bg10 {
    background: linear-gradient(#130303, #898989);
}
@import '../../styles/variables';
@import '../../styles/mixins';

.start {
   padding-bottom: 40px;
   &Main {
        display: grid;
        grid-template-columns: 1fr 2fr;
        &Img {
         text-align: center;
         img {
            margin: auto;
         }
        }
        &Text {
         max-width: 70rem;
         margin-left: 5rem;
        }
        p {
         color: $purple;
         font-size: 3rem;
         font-family: 'Muli';
         font-weight: bold;
         line-height: 1.2;
        }
        &Answers p {
         font-size: 2rem;
        }
   }
   &Heading {
      font-weight: bold;
      font-size: 3rem;
      font-family: 'Muli';
      margin-bottom: 1rem;
      color: $purple;
   }
}
.panel {
   background: #fff;
   padding: 30px;
   border-radius: 20px;
   position: relative;
   &ArrowLeft::after {
      position: absolute;
      top: 50%;
      left: -20px;
      margin-top: -10px;
      content: '';
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 20px solid white;
   }

	     &ArrowLeftTop::after {
	     	position: absolute;
	     	top: 150px;
	     	left: -20px;
	     	content: '';
	     	border-top: 20px solid transparent;
	     	border-bottom: 20px solid transparent;
	     	border-right: 20px solid white;
	     }
}

.progress {
   margin: 3rem 0 5rem 0;
   border-radius: 10rem;
   background: $purple;
   color: #fff;
   text-align: center;
   position: relative;
   padding-bottom: 1rem;
   font-weight: bold;
   &Img {
      margin: auto;
      transform: translateY(-35px);
      width: 42px;
      margin-bottom: -35px
   }
}

.qTitle {
   color: #fff;
   text-align: center;
   font-size: 3rem;
   margin: 0 auto 3rem auto;
   font-weight: bold;
   max-width: 60rem;
   line-height: 1.2;
}

.surveyWrapper {
   padding-bottom: 100px;
}
.choices {
   display: flex;
   margin-bottom: 4rem;
   justify-content: center;
   flex-direction: column;
   gap: 1.2rem;
   &2Cols {
      display: grid;
      .choice {
         display: flex;
         align-items: center;
         text-align: left;
         img {
            margin-right: 1rem;
            flex-basis: 8rem;
            max-width: 8rem;
            flex-shrink: 0;
            margin-bottom: 0;
         }
      }
   }
}

.choice {
   padding: 1.5rem;
   border-radius: 2rem;
   background: #fff;
   display: flex;
   flex-direction: row;
   align-items: center;
   text-align: center;
   cursor: pointer;
   color: $purple;
   &Active {
      background: $primary;
      color: #fff;
   }
   img {
      margin: auto;
      margin-right: 1rem;
      max-width: 70px;
   }
   &Text {
      font-weight: bold;
      font-size: 2.5rem;
      line-height: 1.2;
   }
}

@include breakpoint-up(md) {
   .surveyWrapper {
      padding-bottom: 0;
   }
   .choices {
      flex-direction: row;
   }
   .choices2Cols {
      grid-template-columns: 1fr 1fr;
   }
   .choices2Cols .choice {
      max-width: none;
      flex-direction: row;
   }
   .choice {
      max-width: 170px;
      flex-basis: calc(25% - 2rem);
      flex-direction: column;
      img {
            margin: auto;
            margin-bottom: 1rem;
            max-width: 130px;
         }
   }
}

@include breakpoint-up(lg) {
   .choices {
      flex-wrap: nowrap;
   }
}
.nextBtn {
   width: 30rem;
}
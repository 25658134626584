//Spacing
$spacer: 1rem !default;
$spacers: (
  0: $spacer * 0,
  1: $spacer * 0.5,
  2: $spacer * 1.5,
  3: $spacer * 2.5,
  4: $spacer * 4
)!default;

$space-properties: (
  margin: m,
  padding: p
);

//typography
$base-line-height:            1.6 !default;

$base-font-size:              1.4rem !default;
$lead-font-size:              1.6rem !default;

$base-desktop-font-size:      1.6rem !default;
$lead-desktop-font-size:      1.8rem !default;

$small-font-size:             1.2rem !default;

$body-font-family:            "Muli", sans-serif !default;
$headings-margin-bottom:      $spacer !default;
$headings-font-family:        "Muli", sans-serif !default;
$headings-line-height:        1.2 !default;
$headings-color:              inherit !default;

$h1-font-size:                $base-font-size * 1.8 !default;
$h2-font-size:                $base-font-size * 1.6 !default;
$h3-font-size:                $base-font-size * 1.4 !default;

$h1-desktop-font-size:        $base-desktop-font-size * 1.875 !default;
$h2-desktop-font-size:        $base-desktop-font-size * 1.5 !default;
$h3-desktop-font-size:        $base-desktop-font-size * 1.125 !default;

//Colors
$body-color   : #4A4A4A;
$primary-light: #C2D582;
$primary      : #9DC030;
$primary-dark : #5B7223;
$secondary    : #FFD653;
$danger       : red;
$warning      : darken($secondary, 5%);
$purple: #4E406E;

$dark-green: #6B9A11;


$gray-1:     #D9D9D9 !default;
$gray-2:     #C6CBB5 !default;
$gray-3:     #4A4A4A !default;

$theme-colors: (
  "dark"          : $body-color,
  "white"         : #fff,
  "primary"       : $primary,
  "primary-dark"  : $primary-dark,
  "danger"        : $danger,
  "muted"         : $gray-2,
  "purple"        :$purple
);
